<template>
  <div>
    <a-spin :spinning="processing">
      <a-row type="flex" justify="center">
        <a-col :span="24">
          <a-table
            bordered
            v-if="dataSource.length"
            :dataSource="dataSource"
            :columns="columns"
            :pagination="false"
          >
            <template slot="row_index" slot-scope="text, record, index">
              <p>{{ index + 1 }}</p>
            </template>
            <template slot="user_name" slot-scope="text, record, index">
              <p>
                {{ get(record, "user.nom_fr") }}
                {{ get(record, "user.prenom_fr") }}
              </p>
            </template>
            <template slot="reference" slot-scope="text, record">
              <span v-bind:class="{ black: isNewPaiement(record) }">
                <a-badge v-if="isNewPaiement(record)" status="processing" />
                {{ record.code }}
              </span>
            </template>
            <template slot="exercice_du" slot-scope="text, record, index">
              <p>{{ get(record, "exercice.annee") | year }}</p>
            </template>

            <template slot="piece_jointe" slot-scope="text, record, index">
              <!-- _________________________VISUALIZE -->
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Visualiser</span>
                </template>

                <a-icon
                  type="search"
                  @click="previewDocument(record.file_link)"
                  class="action_icons"
                  v-if="record.file_name"
                />
              </a-tooltip>
            </template>
            <template slot="date_paiement" slot-scope="text, record, index">
              <strong>{{ record.dt_encaissement | date }}</strong>
            </template>
            <template slot="etat" slot-scope="text, record, index">
              <EtatDemandesOuverturesIndicator :etat="record.etat" />
              
            </template>
            <template slot="user" slot-scope="text, record, index">
              {{ record.nom_fr }} {{ record.prenom_fr }}
            </template>
            <template slot="dt_demande" slot-scope="text, record, index">
              {{ date(record.dt_demande) }}
            </template>
            <template
              class="table-operation"
              slot="admin_operation"
              slot-scope="text, record"
            >
              <a-tooltip
                placement="top"
                v-if="isEtatDemandeOuvertureAccepter(record.etat)"
              >
                <template slot="title">
                  <span>Imprimer</span>
                </template>
                <a-icon
                  @click="printDocument(record)"
                  class="action_icons"
                  theme="twoTone"
                  type="printer"
                />
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Edition</span>
                </template>
                <a-icon
                  type="edit"
                  v-on:click="goToUserDemandes(record.user_id)"
                  class="margin_right action_icons"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
              </a-tooltip>

            </template>
            <template
              class="table-operation"
              slot="operation"
              slot-scope="text, record"
            >
              <a-tooltip placement="top" v-if="canEdit(record)">
                <template slot="title">
                  <span>Edition</span>
                </template>
                <a-icon
                  type="edit"
                  v-on:click="onEdit(record.id)"
                  class="margin_right action_icons"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
              </a-tooltip>
              <a-tooltip
                placement="top"
                v-if="isEtatDemandeOuvertureAccepter(record.etat)"
              >
                <template slot="title">
                  <span>Imprimer</span>
                </template>
                <a-icon
                  @click="printDocument(record)"
                  class="action_icons"
                  theme="twoTone"
                  type="printer"
                />
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Les visites</span>
                </template>
                <a-icon
                  type="eye"
                  @click="goToVisite(record.id)"
                  class="margin_right action_icons"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
              </a-tooltip>

              <a-popconfirm
                title="Etes-vous sûr de vouloir supprimer cette ligne ?"
                @confirm="() => onDelete(record.id)"
                v-if="canDelete(record)"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Suppression</span>
                  </template>
                  <a-icon
                    type="delete"
                    class="action_icons"
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                  />
                </a-tooltip>
              </a-popconfirm>
            </template>
            <template
              class="table-operation"
              slot="decision"
              slot-scope="text, record"
            >
              <a-tooltip v-if="record.visites.length" placement="top">
                <template slot="title">
                  <span>Décision</span>
                </template>
                <a-button @click="onEditDeicion(record.id)" type="link">
                  Décision
                </a-button>
              </a-tooltip>
            </template>
            <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
              <div v-if="isDemandeOuvertureTransfert(record.type.code)">
                <p>
                  <b>Wilaya précédent : </b>
                  {{ get(record, "wilaya_precedent.nom_fr") }}
                </p>
                <p>
                  <b>Commune précédent : </b>
                  {{ get(record, "commune_precedent.nom_fr") }}
                </p>
                <p>
                  <b>Addresse précédent : </b>
                  {{ get(record, "adresse_precedent") }}
                </p>
                <p>
                  <b>Addresse précédent (Ar): </b>
                  {{ get(record, "adresse_precedent_ar") }}
                </p>
              </div>
              <p><b>Wilaya : </b> {{ get(record, "wilaya.nom_fr") }}</p>
              <p><b>Commune : </b> {{ get(record, "commune.nom_fr") }}</p>
              <p><b>Addresse</b> : {{ record.adresse }}</p>
              <p><b>Addresse (Ar)</b> : {{ record.adresse_ar }}</p>
              <p><b>Superfice</b> : {{ record.superficie }}</p>
              <p><b>Tél. :</b> {{ record.tel }}</p>
              <p><b>ANSEJ :</b> {{ record.is_ansej ? "Oui" : "Non" }}</p>
              <p v-if="isDemandeOuvertureCabinetGroupe(record.type.code)">
                <b>Les membres :</b>
                {{ getMembers(record) }}
                <br />
                <br />
                <b>Les médecins :</b>
                {{ getMedecins(record) }}
              </p>
            </div>
          </a-table>
          <EmptyResult v-else />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>
<script>
import _map from "lodash-es/map";
import _filter from "lodash-es/filter";
import EmptyResult from "@/components/common/EmptyResult";
import EtatDemandesOuverturesIndicator from "@/components/common/EtatDemandesOuverturesIndicator";
import { mapActions } from "vuex";

let userColumns = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Type demande",
    dataIndex: "type.nom_fr",
  },
  {
    title: "Date demande",
    dataIndex: "dt_demande",
    scopedSlots: { customRender: "dt_demande" },
  },
  {
    title: "Etat",
    scopedSlots: { customRender: "etat" },
  },
  {
    title: "Actions",
    scopedSlots: { customRender: "operation" },
  },
];
let adminColumns = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Nom & Prénom",
    scopedSlots: { customRender: "user_name" },
  },
  {
    title: "Type demande",
    dataIndex: "type.nom_fr",
  },
  {
    title: "Date demande",
    dataIndex: "dt_demande",
    scopedSlots: { customRender: "dt_demande" },
  },
  {
    title: "Etat",
    scopedSlots: { customRender: "etat" },
  },
  {
    title: "Actions",
    scopedSlots: { customRender: "admin_operation" },
  },
];
let adminColumnsWithActions = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Nom & Prénom",
    scopedSlots: { customRender: "user_name" },
  },
  {
    title: "Type demande",
    dataIndex: "type.nom_fr",
  },
  {
    title: "Date demande",
    dataIndex: "dt_demande",
    scopedSlots: { customRender: "dt_demande" },
  },
  {
    title: "Etat",
    scopedSlots: { customRender: "etat" },
  },
  {
    title: "Decision",
    scopedSlots: { customRender: "decision" },
  },
  {
    title: "Actions",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  name: "ListDemandesOuvertures",
  components: {
    EmptyResult,
    EtatDemandesOuverturesIndicator,
  },
  data() {
    return {
      columns: this.isAdmin ? this.getAdminColumns() : userColumns,
    };
  },
  props: {
    dataSource: Array,
    processing: {
      type: Boolean,
      default: () => false,
    },
    isAdmin: {
      type: Boolean,
      default: () => false,
    },
    displayDecisionActions: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    goToUserDemandes(userId) {
      this.$router.push(`adherents/demandes/edit/${userId}`);
    },
    getMembers(demande) {
      let members = _filter(demande.members, { responsable: false });
      return _map(members, (value) => value.full_name).toString();
    },
    getMedecins(demande){
      return _map(demande.medecins, (value) => value.full_name).toString();
    },
    getAdminColumns() {
      return this.displayDecisionActions
        ? adminColumnsWithActions
        : adminColumns;
    },
    printDocument(demande) {
      this.startProcessing();
      this.callPdfGenerator({
        id: demande.user.id,
        type: this.get(demande, "type.rapport.type"),
        organisation: demande.user.organisation_id,
        id_demande: demande.id,
      })
        .catch(() => this.$_throwAnError())
        .finally(() => this.stopProcessing());
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    canDelete(demande) {
      return this.isAdmin || this.isEtatDemandeOuvertureEnAttente(demande.etat);
    },
    canEdit(demande) {
      return this.isAdmin || this.isEtatDemandeOuvertureEnAttente(demande.etat);
    },
    onDelete(id) {
      this.$emit("delete", id);
    },
    onEditDeicion(id) {
      this.$emit("decicion_change", id);
    },
    onEdit(id) {
      this.$emit("edit", id);
    },
    goToVisite(id) {
      this.$emit("visite", id);
    },
    previewDocument(link) {
      window.open(link);
    },
    ...mapActions({
      callPdfGenerator: "callPdfGenerator",
    }),
  },
};
</script>
<style scoped>
.action_icons {
  margin: 4px;
  font-size: large;
}

.margin_right {
  margin-right: 5px;
}
</style>
